
import {
  PageHeader,
  Tag,
  Button,
  Statistic,
  Descriptions,
  Row,
} from "ant-design-vue";
import AbpBase from "../../lib/abpbase";
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class PeopleHeader extends AbpBase {
  name: "people-header";
  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: Boolean, default: false }) isForm: boolean;
  @Prop({ type: Boolean, default: false }) disabled: boolean;
  @Prop({ type: String }) id: string;
  @Prop({ type: String }) pageTitle: string;

  async created() {}
}
