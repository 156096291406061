
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import EditCliente from './edit-cliente.vue'
import PeopleFind from "../../../components/People/people-find.vue";

class PageClienteRequest extends PageRequest {
  keyword: string
  nombre: string
  cIF: string
}

@Component({
  components: {
    EditCliente,
    PeopleFind
  }
})
export default class Cliente extends AbpBase {
  //filters
  pagerequest: PageClienteRequest = new PageClienteRequest()
  creationTime: Date[] = []

  createModalShow: boolean = false
  editModalShow: boolean = false
  get list() {
    return this.$store.state.cliente.list
  }
  get loading() {
    return this.$store.state.cliente.loading
  }
  async create() {
    this.createModalShow = true
    this.$router.push({ name: 'editcliente' })
  }
  editRow(item, index, button) {
    this.$store.commit('cliente/edit', item)
    this.edit()
    this.$router.push({ name: 'editcliente', params: { id: item.id } })
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('¿Desea eliminar este cliente?'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Sí'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: 'cliente/delete',
            data: item
          })
          await this.getpage()
        }
      })
  }
  edit() {
    this.editModalShow = true
  }

  pageChange(page: number) {
    this.$store.commit('cliente/setCurrentPage', page)
    this.getpage()
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit('cliente/setPageSize', pagesize)
    this.getpage()
  }
  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize

    await this.$store.dispatch({
      type: 'cliente/getAllWithImages',
      data: this.pagerequest
    })

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.cliente.totalCount;
    pagination.pageSize = this.$store.state.cliente.pageSize;
    pagination.current = this.$store.state.cliente.currentPage;
    this.pagination = pagination;
  }
  get pageSize() {
    return this.$store.state.cliente.pageSize
  }
  get totalCount() {
    return this.$store.state.cliente.totalCount
  }
  get currentPage() {
    return this.$store.state.cliente.currentPage
  }
  set currentPage(page) {
    this.$store.commit('cliente/setCurrentPage', page)
    this.getpage()
  }

  async created() {
    this.editModalShow = false
    this.createModalShow = false
    this.getpage()
  }
  async mounted() {
    this.editModalShow = false
    this.createModalShow = false
    this.getpage()
  }

  getImage(value: string) {
    return `data:image/png;base64,${value}`
  }

  columns = [
    {
      title: this.L('Nombre'),
      dataIndex: 'nombre',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Pais'),
      dataIndex: 'pais',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Imagen'),
      dataIndex: 'fileContent',
      scopedSlots: { customRender: "img" },
      thClass: 'w-auto',
      tdClass: 'w-auto text-nowrap'
    },
    {
      title: this.L('Fecha Creacion'),
      dataIndex: 'creationTime',
      scopedSlots: { customRender: "date" },
      formatter: (value, key, item) => {
        return new Date(value).toLocaleDateString('es-ES')
      },
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ];

  pagination: any = {};
  findModalShow: boolean = false;

  searchDefinition: {}[] = [
    {
      fieldName: "Filtro",
      fieldType: "text",
      fieldBind: "filter",
      value: "",
    },
    {
      fieldName: "Nombre",
      fieldType: "text",
      fieldBind: "nombre",
      value: "",
    },
    {
      fieldName: "CIF",
      fieldType: "text",
      fieldBind: "cif",
      value: "",
    },
  ];

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit(
      "cliente/setCurrentPage",
      pagination.current
    );
    //this.getpage();

    await this.getpage();
  };

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });

    //this.pagerequest.idcampaña =;
    await this.getpage();
    this.findModalShow = false;
  };
}
