
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Modal from "../../../components/Modal.vue";
import BaseButton from "../../../components/BaseButton.vue";
import Cliente from "../../../store/entities/OZONE/cliente";
import Marca from "../marca/marca.vue";
import PeopleHeader from "../../../components/People/people-header.vue";
@Component({
  components: { Modal, BaseButton, Marca, PeopleHeader },
})
export default class EditCliente extends AbpBase {
  name: "edit-cliente";
  // @Prop({ type: Boolean, default: false }) value: boolean
  // @Prop({ type: Boolean, default: false }) editing: boolean
  // @Prop({ type: String }) id: string
  editing: boolean;
  cliente: Cliente = new Cliente();
  title: String = "";
  fileRecords = [];
  inputPais: any = null;
  uploadHeaders = { "X-Test-Header": "vue-file-agent" };
  fileRecordsForUpload = []; // maintain an upload queue
  pais = [
    { label: "España", key: "ES" },
    { label: "Italia", key: "IT" },
  ];
  disabled: boolean = false;

  async save(e) {
    e.preventDefault();
    if (this.inputPais) {
      this.cliente.pais = this.inputPais.label;
      if (this.fileRecords.length > 0) {
        this.cliente.imagen = this.fileRecords[0].name();
        if (this.fileRecords[0].lastModified === 0) {
          this.cliente.fileContent = this.fileRecords[0].url;
        } else {
          this.cliente.fileContent = this.fileRecords[0].urlResized;
        }
      } else {
        this.cliente.imagen = null;
        this.cliente.fileContent = null;
      }
      if (this.editing) {
        await this.$store
          .dispatch({
            type: "cliente/update",
            data: this.cliente,
          })
          .then(() => this.close());
      } else {
        await this.$store
          .dispatch({
            type: "cliente/create",
            data: this.cliente,
          })
          .then(() => this.close());
      }
    }
  }
  close() {
    //this.$emit('input', false)
    this.resetFields();
    this.$router.go(-1);
  }

  mounted() {
    this.editing = this.$route.params.id != null;

    if (this.editing) {
      this.cliente = Util.extend(
        true,
        {},
        this.$store.state.cliente.editCliente
      );

      this.inputPais = this.pais.find(e => e.label == this.cliente.pais);

      if (this.cliente.fileContent && this.cliente.fileContent.length > 0) {
        var imagen = {
          name: this.cliente.imagen,
          lastModified: 0,
          sizeText: "549 KB",
          size: this.cliente.fileContent.length,
          type: "image/*",
          dimensions: {
            width: 640,
            height: 360,
          },
          url: `data:image/png;base64,${this.cliente.fileContent}`,
        };

        this.fileRecords.push(imagen);
      }
    }
    if (this.editing) {
      this.title = this.L("Editar Cliente");
    } else {
      this.title = this.L("Nuevo Cliente");
    }
    this.setDisabled();
  }
  // async shown() {
  //
  //   if (this.editing) {
  //     this.cliente = Util.extend(true, {}, this.$store.state.cliente.editCliente)
  //   }
  //   if (this.editing) {
  //     this.title = this.L('EditCliente')
  //   } else {
  //     this.title = this.L('AddCliente')
  //   }
  // }
  // destroyed() {
  //
  //   ;(this.$parent as any).editModalShow = false
  //   ;(this.$parent as any).createModalShow = false
  // }

  filesSelected(fileRecordsNewlySelected) {
    var validFileRecords = fileRecordsNewlySelected.filter(
      (fileRecord) => !fileRecord.error
    );
    this.fileRecordsForUpload =
      this.fileRecordsForUpload.concat(validFileRecords);
  }
  onBeforeDelete(fileRecord) {
    var i = this.fileRecordsForUpload.indexOf(fileRecord);
    if (i !== -1) {
      // queued file, not yet uploaded. Just remove from the arrays
      this.fileRecordsForUpload.splice(i, 1);
      var k = this.fileRecords.indexOf(fileRecord);
      if (k !== -1) this.fileRecords.splice(k, 1);
    } else {
      this.$bvModal
        .msgBoxConfirm(this.L("¿Desea eliminar esta imagen?"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.L("Sí"),
          cancelTitle: this.L("No"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            (this.$refs.vueFileAgent as any).deleteFileRecord(fileRecord); // will trigger 'delete' event
          }
        });
    }
  }
  fileDeleted(fileRecord) {
    var i = this.fileRecordsForUpload.indexOf(fileRecord);
    if (i !== -1) {
      this.fileRecordsForUpload.splice(i, 1);
    } else {
      this.deleteUploadedFile(fileRecord);
    }
  }
  async deleteUploadedFile(fileRecord) {
    await this.$store.dispatch({
      type: "cliente/deleteClienteFileAsync",
      data: this.cliente,
    });
  }

  checkFormValidity() {
    const valid = (this.$refs.clienteForm as any).checkValidity();
    return valid;
  }
  resetFields() {
    this.cliente = new Cliente();
  }

  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted(
      "OZONE.Clientes.Edit",
      "OZONE.Clientes.Create",
      "OZONE.Clientes.Delete"
    );
  }

  clienteRule = {
    nombre: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Nombre")),
      placeholder: this.L("Nombre"),
      trigger: "blur",
    },
    pais: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Pais")),
      placeholder: this.L("Pais"),
      trigger: "blur",
    },
  };
}
